<template>
  <div style="padding: 0px">
    <iframe id="gstsite" src="https://www.gst.gov.in" width="100%" style="border: 0px">

    </iframe>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" ><i class="icon-reset" style="color: blue"></i>Refresh</a>
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

  </div>
</template>

<script>
  export default {
    name: 'GstPortalView',
    components:{

    },
    mounted(){
      $('#gstsite').contextmenu({
        target: '#context-menu',
      //  scopes: 'iframe',
        onItem: function (row, e) {


        }
      });



      $(window).resize(function(){
        $('#gstsite').height($("#appfooter").offset().top - $("#gstsite").offset().top-7);
      });
      $(window).resize();

    },
    methods:{
      onLoad(){

      },
      onDocumentLoad(){

      }
    }
  }
</script>

<style scoped>

</style>
